import styled, { keyframes } from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const animate1 = keyframes`
  0% {
    transform: translate(0, 0);
  }

  10% {
    transform: translate(5px, -5px);
  }
  
  20% {
    transform: translate(7px, -5px);
  }

  30% {
    transform: translate(5px, -5px);
  }

  40% {
    transform: translate(7px, 0);
  }

  50% {
    transform: translate(7px, 7px);
  }

  60% {
    transform: translate(5px, 7px);
  }

  70% {
    transform: translate(5px, 5px);
  }

  80% {
    transform: translate(0px, 5px);
  }

  90% {
    transform: translate(2px, 2px);
  }

  100% {
    transform: translate(0, 0);
  }
`;

const StyledNotFound = styled.main`
  min-height: 90vh;
  overflow: hidden;
  position: relative;

  ${breakpoints.MD} {
    min-height: 70vh;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 4fr);
  grid-template-areas:
    'title'
    'description'
    'action';
  column-gap: 18px;
  row-gap: ${({ theme }) => theme.spacings.x4};
  padding: 220px 0 80px;

  ${breakpoints.MD} {
    grid-template-columns: repeat(12, 12fr);
    column-gap: ${({ theme }) => theme.spacings.x4};
    row-gap: ${({ theme }) => theme.spacings.x4};
    padding-top: 288px;
  }

  ${breakpoints.MDL} {
    padding-top: 252px;
  }

  ${breakpoints.LG} {
    padding-top: 370px;
    padding-bottom: 180px;
  }
`;

const Ticker = styled.h1`
  white-space: nowrap;
  width: 100%;
  grid-area: title;
  position: absolute;
  left: -18px;
  top: ${({ theme }) => theme.spacings.x20};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  & > * {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding-right: 30px;
  }

  ${breakpoints.MD} {
    top: 240px;
  }

  ${breakpoints.MDL} {
    top: ${({ theme }) => theme.spacings.x20};
  }
`;

const Eyes = styled.div`
  transform: translateY(-25%);
  padding: 0 38px;
  display: flex;
  align-items: center;

  svg {
    .eye-main,
    .eye-secondary {
      animation: 3s linear infinite ${animate1};
    }
  }

  svg + svg {
    margin-left: 8px;
  }
`;

const Description = styled.p`
  grid-area: description;
  grid-column-start: 2;
  grid-column-end: 5;
  padding-right: 27%;

  ${breakpoints.MD} {
    grid-column-end: 6;
    padding-right: 0;
  }

  ${breakpoints.MDL} {
    grid-column-start: 3;
  }

  ${breakpoints.LG} {
    grid-column-start: 3;
    grid-column-end: 5;
  }
`;

const Action = styled.div`
  grid-area: action;
  grid-column-start: 2;
  grid-column-end: 5;

  ${breakpoints.MD} {
    grid-column-start: 6;
    grid-column-end: 9;
  }

  ${breakpoints.MDL} {
    margin-top: -${({ theme }) => theme.spacings.x4};
  }

  ${breakpoints.LG} {
    margin-top: -${({ theme }) => theme.spacings.x4};
  }
`;

export { StyledNotFound, Ticker, Description, Action, Grid, Eyes };
