import NotFound from '@/scenes/404';
import { initializeRootStore } from '@/stores';
import { getSnapshot } from 'mobx-state-tree';

const PageNotFound = () => {
  return <NotFound />;
};

export const getStaticProps = async () => {
  const rootStore = initializeRootStore();

  await rootStore.uiStore.setPreloaded(true);

  return {
    props: {
      initialState: getSnapshot(rootStore),
    },
  };
};

export default PageNotFound;
