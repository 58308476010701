import {
  Action,
  Description,
  Eyes,
  Grid,
  StyledNotFound,
  Ticker,
} from './styles';
import Button from '@/components/UI/Button';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import { gsap, Power0 } from 'gsap';

const LeftEye = (
  <svg
    width="51"
    height="49"
    viewBox="0 0 51 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M28.8913 0.647724L31.0685 1.43937L36.0762 3.31054C35.6407 3.74234 34.407 3.45447 33.2458 2.87873L27.8752 3.1666C27.5849 3.59841 27.1495 3.95825 26.5689 4.10218H26.4963C23.9562 4.67793 21.6338 5.3976 19.4566 6.54909C17.4245 7.62861 15.6101 9.13993 13.9409 10.7952C12.3443 12.5224 10.9653 14.4656 10.0219 16.4807L9.36871 17.992L9.07842 18.7836L8.86068 19.5753C8.57038 20.6548 8.35266 21.7343 8.13493 22.8138C7.77206 24.9729 7.4092 27.2758 7.6995 29.7228L7.84464 30.6583C7.91716 31.0179 7.98969 31.3056 8.06221 31.5933L8.06238 31.5939L8.28009 32.5295L8.64296 33.3931C8.86069 34.0408 9.15099 34.5446 9.44129 35.1203C9.73159 35.6961 10.0945 36.1999 10.4573 36.7036C13.3603 40.8778 18.0777 44.0444 23.3031 44.908H23.3756C24.1014 45.0519 24.5368 45.7716 24.3917 46.4913C24.2465 47.211 23.5208 47.6428 22.795 47.4988C18.5857 46.5632 14.5215 44.836 11.0379 42.1732L8.28009 39.7263C7.98979 39.5104 7.77207 39.2225 7.55434 39.0066C7.04632 38.4309 6.5383 37.9271 6.10285 37.2794L3.63532 33.681L2.32896 31.45C2.32896 31.45 1.42418 29.4342 0.950045 28.0675C0.475907 26.7007 -0.0659914 24.1093 0.00658324 22.0942C0.00658324 21.3025 0.0791488 20.4389 0.224298 19.6472C0.369447 18.8556 0.51461 18.0639 0.804909 17.2723L1.16778 16.1208L1.60321 14.9693C1.89351 14.1777 2.32896 13.458 2.69183 12.7383C4.28848 9.85962 6.39314 7.41271 8.86068 5.32564C10.0944 4.31809 11.4008 3.38251 12.8523 2.66283C13.2152 2.44692 13.578 2.23102 13.9409 2.08708C14.3037 1.8712 14.6666 1.72728 15.0294 1.58336L15.0295 1.58331L19.5292 0.287888C20.9807 0.0719833 22.3596 3.8147e-06 23.8111 3.8147e-06L28.8913 0.647724Z"
      fill="black"
    />
    <path
      d="M21.0568 45.9155C22.3632 46.2034 24.1775 46.5632 26.3548 46.4913C26.3548 46.4913 30.9996 46.4913 35.2815 44.6921C43.3373 41.3096 48.9981 33.4651 48.9981 24.3251C48.9981 12.0906 38.8376 2.231 26.3548 2.231C23.1615 2.231 20.1859 2.87871 17.4281 4.0302C10.7512 6.83694 3.63891 14.6095 3.71149 24.3971C3.78406 34.6885 11.6947 44.0444 22.7986 46.2034"
      fill="#DDDDDD"
    />
    <path
      d="M20.6195 47.7146C22.2162 48.0745 23.8128 48.2904 25.4095 48.2904C27.0787 48.3624 28.6753 48.1465 30.1994 47.9305C31.7961 47.7146 33.3201 47.2828 34.8442 46.7791C36.3683 46.2753 37.8198 45.4836 39.1987 44.692C44.7143 41.3815 48.7785 35.8399 50.23 29.5787C51.6815 23.3895 50.5203 16.5525 46.9641 11.1549C43.4806 5.75734 37.6746 1.87108 31.3606 0.647629C25.0466 -0.647793 18.152 0.431721 12.7089 4.17405C10.0236 5.97325 7.70126 8.27623 5.8869 10.939C4.94343 12.2345 4.21767 13.6738 3.5645 15.1132C3.2742 15.8329 2.9839 16.6245 2.76617 17.4162C2.62102 17.776 2.54845 18.2078 2.4033 18.5676L2.18558 19.7191C0.879239 26.1243 2.69361 32.8893 6.61264 37.855C10.5317 42.8928 16.3377 46.3473 22.5791 47.2828C23.3048 47.4268 23.958 46.923 24.1031 46.2033C24.2483 45.5556 23.7403 44.9079 23.0871 44.692C17.5714 43.3966 12.7089 40.158 9.51563 35.696C6.39492 31.234 4.94342 25.6205 6.10461 20.4388C7.19323 15.2571 10.6042 10.5072 15.0313 7.48458C16.1199 6.69293 17.3537 6.11719 18.5875 5.61341C19.8212 5.10963 21.1276 4.7498 22.4339 4.46193C25.1192 3.95816 27.877 3.95816 30.4897 4.46193C35.7877 5.46948 40.6502 8.70803 43.6983 13.17C46.7464 17.6321 47.835 23.3175 46.6013 28.6431C45.4401 33.8968 41.8839 38.6467 37.2391 41.4534C36.0779 42.1011 34.8442 42.7489 33.5378 43.1807C32.2315 43.6125 30.9251 43.9723 29.5462 44.1882C28.1673 44.4041 26.7884 44.5481 25.4821 44.4041C24.1031 44.3322 22.7968 44.1163 21.4179 43.8284C20.4018 43.6125 19.3132 44.1882 19.0955 45.1958C18.9503 46.4192 19.5309 47.4987 20.6195 47.7146Z"
      fill="black"
    />
    <path
      d="M19.8591 15.6251C24.2556 15.6251 27.8197 19.1892 27.8197 23.5857C27.8197 27.9822 24.2556 31.5463 19.8591 31.5463C15.4625 31.5463 11.8984 27.9822 11.8984 23.5857C11.8984 19.1892 15.4625 15.6251 19.8591 15.6251Z"
      fill="black"
      stroke="black"
      strokeWidth="3.6388"
      strokeMiterlimit="10"
      className="eye-main"
    />
    <path
      d="M22.444 22.3694C21.9535 20.9572 24.3214 19.2149 26.7567 18.6799C28.7539 18.2635 31.8353 18.4533 32.6979 19.8365C33.5606 21.2198 31.737 23.219 29.6014 23.9532C26.8427 24.7973 22.9918 23.877 22.444 22.3694Z"
      fill="white"
      className="eye-secondary"
    />
  </svg>
);

const RightEye = (
  <svg
    width="44"
    height="43"
    viewBox="0 0 44 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M14.5734 0.742176C16.3877 0.238403 18.2747 0.0224915 20.089 0.0944595L25.5321 1.17398C25.4595 1.46185 24.8064 1.60579 23.7903 1.53382C23.7903 1.60579 23.8629 1.67776 23.8629 1.74973C24.1532 2.75727 23.5726 3.76482 22.5566 4.0527H22.484C20.3793 4.62844 18.4198 5.42009 16.7506 6.42764C15.8797 6.93142 15.1539 7.43519 14.4282 8.0829C13.7025 8.65862 13.0494 9.37826 12.3962 10.0979L12.3961 10.098C11.1623 11.5374 10.0737 13.1926 9.27539 14.8479C8.54965 16.5031 7.89648 18.2304 7.46103 20.1015C7.02558 21.9727 6.80786 23.9878 7.09815 26.0029C7.17073 26.5067 7.24332 27.0105 7.38847 27.5142L7.38854 27.5145C7.53367 28.0182 7.67879 28.5219 7.89648 29.0256L8.18677 29.7452L8.54965 30.4649C8.76737 30.9687 9.05767 31.4005 9.34797 31.8323C11.5978 35.4307 15.4442 38.1655 19.7987 39.1011C20.3793 39.173 20.8148 39.8208 20.6696 40.3965C20.597 40.9722 19.9439 41.404 19.3633 41.2601C14.5008 40.3245 9.92857 37.8057 6.51757 33.9914L6.51747 33.9912C6.08206 33.4875 5.64664 32.9838 5.28379 32.48L4.7032 31.6884L4.19517 30.8967C4.05002 30.6808 3.83231 30.393 3.68716 30.1051L3.17913 29.2415C2.88883 28.6657 2.59855 28.018 2.30825 27.4423C1.21963 25.0673 0.4213 22.3326 0.348726 19.5258C0.276151 16.7191 1.0019 13.9123 2.23567 11.3934C3.46944 8.87455 5.2838 6.71551 7.31589 4.84435L7.31636 4.84394C7.82423 4.41227 8.3321 3.98059 8.91252 3.54892C9.49312 3.11712 10.0012 2.75727 10.6543 2.39743C11.2349 2.03761 11.888 1.74975 12.5412 1.46189L12.5413 1.46186C13.2669 1.17401 13.9201 0.958118 14.5732 0.742233L14.5734 0.742176Z"
      fill="black"
    />
    <path
      d="M18.2823 39.9648C19.3709 40.2527 20.8224 40.4686 22.6367 40.4686C22.6367 40.4686 26.4832 40.4686 29.9668 38.9572C36.6436 36.0066 41.2884 29.3135 41.2884 21.3971C41.2884 10.8898 32.9424 2.32557 22.6367 2.32557C20.0241 2.32557 17.5565 2.90131 15.3067 3.83689C9.79102 6.2838 3.91247 12.9768 3.98504 21.3971C3.98504 30.2491 10.5168 38.3815 19.7338 40.2527"
      fill="#DDDDDD"
    />
    <path
      d="M17.9176 41.5481C19.224 41.8359 20.6029 42.0518 21.9818 42.0518C23.4333 42.1238 24.7396 41.9079 26.046 41.692C27.3523 41.4761 28.6587 41.1163 29.965 40.6125C31.2714 40.1087 32.4326 39.461 33.5938 38.7413C38.166 35.7906 41.3593 30.9688 42.5205 25.7151C43.6816 20.4615 42.7382 14.776 39.8352 10.1701C37.0048 5.56412 32.2148 2.03769 26.8443 0.958168C21.4738 -0.193317 15.5952 0.8862 11.0956 4.19672C10.007 4.98837 8.99094 5.92396 8.04747 6.93151C7.17658 7.93906 6.30568 9.01858 5.57994 10.1701C4.05587 12.4011 3.11239 15.0639 2.60437 17.7267C1.6609 23.1243 3.03982 28.8098 6.2331 33.0559C7.82975 35.2149 9.78927 37.0861 12.1117 38.4535C14.3615 39.8208 16.9016 40.8284 19.5143 41.2602C20.0949 41.3322 20.6029 40.9723 20.748 40.3966C20.8206 39.8928 20.4577 39.389 19.9497 39.2451C15.3049 38.0936 11.3133 35.2149 8.77322 31.3286C6.2331 27.5143 5.14448 22.7644 6.08795 18.3024C6.95884 13.9124 9.6441 9.81023 13.2728 7.14741C14.2163 6.4997 15.1598 5.92396 16.1758 5.49215C17.1919 5.06034 18.2805 4.70051 19.3691 4.4846C21.5464 4.0528 23.8687 3.98083 26.1186 4.4846C30.5456 5.34822 34.5372 8.22693 37.0048 12.0412C39.4723 15.8555 40.3432 20.6774 39.3997 25.1394C38.5288 29.6014 35.771 33.7036 31.852 36.1505C30.9085 36.7982 29.8925 37.302 28.8038 37.7338C27.7878 38.0936 26.6266 38.4534 25.4654 38.5974C24.3042 38.8133 23.143 38.8853 22.0544 38.8133C20.8932 38.7413 19.8046 38.5974 18.7159 38.3095C17.845 38.0936 16.9016 38.5974 16.6839 39.533C16.3936 40.3966 16.9742 41.3322 17.9176 41.5481C17.8451 41.5481 17.9176 41.5481 17.9176 41.5481Z"
      fill="black"
    />
    <path
      d="M18.4301 12.6251C22.2532 12.6251 25.3524 15.7243 25.3524 19.5474C25.3524 23.3705 22.2532 26.4697 18.4301 26.4697C14.607 26.4697 11.5078 23.3705 11.5078 19.5474C11.5078 15.7243 14.607 12.6251 18.4301 12.6251Z"
      fill="black"
      stroke="black"
      strokeWidth="3.6388"
      strokeMiterlimit="10"
      className="eye-main"
    />
    <path
      d="M19.978 17.9112C19.6254 16.7012 21.5877 15.2875 23.5117 14.9586C25.0898 14.6444 27.5359 14.9056 28.1774 16.0958C28.8189 17.2859 27.2965 18.9198 25.5276 19.4438C23.4009 20.1176 20.3227 19.2111 19.978 17.9112Z"
      fill="white"
      className="eye-secondary"
    />
  </svg>
);

const NotFound = () => {
  const tickerRef = useRef(null);
  const textRef = useRef(null);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!loaded) return;

    const textWidth = textRef.current.offsetWidth;

    const tl = gsap.timeline({ repeat: -1 });

    tl.to(tickerRef.current, {
      duration: 10,
      x: `-=${textWidth}`,
      ease: Power0.easeNone,
      onComplete: () => {
        const style = window.getComputedStyle(tickerRef.current);
        const matrix = new WebKitCSSMatrix(style.webkitTransform);

        if (matrix.m41 <= -textWidth) {
          gsap.set(tickerRef.current, {
            x: 0,
          });
        }

        tl.invalidate();
      },
    });

    return () => {
      tl.kill();
    };
  }, [loaded]);

  return (
    <StyledNotFound>
      <div className="container">
        <Grid>
          <Ticker ref={tickerRef}>
            <div ref={textRef}>
              <span className="text-branded">404</span>
              <Eyes>
                {LeftEye}
                {RightEye}
              </Eyes>
              <span>Page not found</span>
            </div>
            {loaded && (
              <div>
                <span className="text-branded">404</span>
                <Eyes>
                  {LeftEye}
                  {RightEye}
                </Eyes>
                <span>Page not found</span>
              </div>
            )}
          </Ticker>
          <Description>
            Sorry, we couldn’t find the page you were looking for.
          </Description>
          <Action>
            <Link href="/">
              <Button type="primary" as="a" href="/">
                Homepage
              </Button>
            </Link>
          </Action>
        </Grid>
      </div>
    </StyledNotFound>
  );
};

export default NotFound;
